export const validateImage = (file: File, acceptedFileTypes: string, size: number, allowedSize: string) => {
  const maxSize = size; //byte
  const acceptedFileTypesArray = acceptedFileTypes?.split(",").map((item) => {
    return item.trim();
  });
  if (!acceptedFileTypesArray.includes(file.type)) {
    return "File format is not supported!";
  } else if (file.size > maxSize) {
    return `Maximum size allowed is ${allowedSize}!`
  } else return "";
};
