import { FC } from "react";
import { cx } from "../../../utils";

interface IEditorTab {
  title: string;
  onClick: () => void;
  isCurrent: boolean;
}

export const EditorTab: FC<IEditorTab> = ({ title, isCurrent, onClick }) => {
  return (
    <button
      type="button"
      className={cx([
        "pb-2 border-b-2 mr-4 transition hover:opacity-80 text-primary-mainText",
        isCurrent ? "  border-primary-hover font-bold" : "border-transparent",
      ])}
      onClick={onClick}
    >
      {title}
    </button>
  );
};
