import { BtnStyle, Button } from "../../components";
import { cx, warningBtnClasses } from "../../utils";
import { DividedSection } from "../layout/DividedSection";
import { Paragraph } from "./Paragraph";

interface IDeleteSection {
  className?: string;
  title: string;
  message?: string;
  onDelete?: () => void;
  mainWrapperClassName?: string;
  innerWrapperClassName?: string;
}

export const DeleteSection: React.FC<IDeleteSection> = (props) => {
  return (
    <div className={cx([props.mainWrapperClassName])}>
      <DividedSection>Sensitive Actions</DividedSection>
      <div
        className={cx([
          "flex flex-col justify-between items-start flex-wrap sm:flex-row",
          props.className,
        ])}
      >
        <div className={cx(["flex-1 pr-9", props.innerWrapperClassName])}>
          <h6 className="text-sm text-primary-mainText font-medium break-words">
            {props.title}
          </h6>
          <Paragraph>{props.message}</Paragraph>
        </div>

        <Button
          type="button"
          btnStyle={BtnStyle.warning}
          onClick={props.onDelete}
          className={cx(["mt-4 sm:mt-0", warningBtnClasses])}
        >
          Delete
        </Button>
      </div>
    </div>
  );
};
