import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Select from 'react-select';
import {
  FormControl,
  Input,
  InputSize,
  Label,
  Message,
  Textarea,
} from '../../../../components';
import {
  cx,
  inputClasses,
  reactSelectStyle,
  textareaClasses,
  validateUrl,
} from '../../../../utils';
import { expectedSuccessStatusCodesData, requestMethodTypes } from '../../dummy-data';
import { RequestHeaders } from './RequestHeaders';

interface IOptonApiAccordion {
  index: number;
  arrayName: string;
  errors: any;
  hideUrl?: boolean;
}

export const OptionApiAccordion: FC<IOptonApiAccordion> = ({
  index,
  arrayName,
  errors,
  hideUrl = false,
}) => {
  const { register, control } = useFormContext();
  return (
    <>
      {!hideUrl && (
        <FormControl>
          <Label required text="URL" htmlFor="url" />
          <Input
            id="url"
            {...register(`${arrayName}.url`, {
              required: 'URL is required',
              validate: (value) =>
                validateUrl(value) || 'Please enter a valid URL.',
            })}
            error={!!errors?.url && errors?.url?.message}
            inputSize={InputSize.sm}
            className={inputClasses}
            placeholder="Enter URL"
          />
        </FormControl>
      )}

      <RequestHeaders index={index} arrayName={`${arrayName}.headers`} />

      <FormControl className="mt-8">
        <Label required text="Request Method" />
        <Controller
          name={`${arrayName}.methodType`}
          control={control}
          rules={{
            required: true,
          }}
          render={({ field: { onChange, ref, value } }) => (
            <Select
              noOptionsMessage={() => 'No Request Method found.'}
              options={requestMethodTypes}
              getOptionLabel={(x) => x?.label}
              getOptionValue={(x) => x?.value}
              isSearchable
              onChange={onChange}
              ref={ref}
              value={!!value?.label ? value : null}
              menuPosition="fixed"
              menuPortalTarget={document.body}
              className={cx(['text-sm', errors?.methodType && 'invalid-field'])}
              classNamePrefix="porta-react-select"
              placeholder="Method Type"
              styles={reactSelectStyle}
            />
          )}
        />
        {errors?.methodType && <Message message="Method Type is required" />}
      </FormControl>

      <FormControl>
        <Label text="Body" htmlFor="body" />
        <Textarea
          id="body"
          {...register(`${arrayName}.body`)}
          className={textareaClasses}
        />
      </FormControl>

      <FormControl>
        <Label required text="Expected HTTP Status Code" />
        <Controller
          name={`${arrayName}.expectedSuccessStatusCodes`}
          control={control}
          rules={{
            required: true,
          }}
          render={({ field: { onChange, ref, value } }) => (
            <Select
              noOptionsMessage={() => 'No expected HTTP Status Code found.'}
              options={expectedSuccessStatusCodesData}
              getOptionLabel={(x) => x?.label}
              getOptionValue={(x) => x?.value}
              isSearchable
              onChange={onChange}
              ref={ref}
              value={value || null}
              menuPosition="fixed"
              menuPortalTarget={document.body}
              className={cx([
                'text-sm',
                errors?.expectedSuccessStatusCodes && 'invalid-field',
              ])}
              classNamePrefix="porta-react-select"
              placeholder="Expected HTTP Status Code"
              styles={reactSelectStyle}
              isMulti
            />
          )}
        />
        {errors?.expectedSuccessStatusCodes && (
          <Message message="Expected Status Code is required" />
        )}
      </FormControl>

      <FormControl description="This message is displayed to end-users in case of an error occurrence during the communication, otherwise there is a default error message">
        <Label text="Error Message (Optional)" htmlFor="errorMessage" />
        <Input
          id="errorMessage"
          {...register(`${arrayName}.errorMessage`)}
          inputSize={InputSize.sm}
          className={inputClasses}
          placeholder="Enter Error Message"
        />
      </FormControl>
    </>
  );
};
