import { FC } from 'react';
import { Label } from '../../../../components';
import { ModelDetailsItem } from './ModelDetailsItem';
import { RequestHeadersDetails } from './RequestHeadersDetails';

interface IOptonApiAccordionDetails {
  accordionData: any;
}

export const OptionApiAccordionDetails: FC<IOptonApiAccordionDetails> = ({
  accordionData,
}) => {
  return (
    <>
      <ModelDetailsItem label="URL" value={accordionData?.url} />

      <RequestHeadersDetails headers={accordionData?.headers} />

      <ModelDetailsItem
        label="Request Method"
        value={accordionData?.methodType}
      />

      <ModelDetailsItem label="Body" value={accordionData?.body} />

      <div className="mb-6">
        <Label text="Expected HTTP Status Code" />
        <div className='flex items-center'>
          {accordionData?.expectedSuccessStatusCodes?.map(
            (statusCode: string) => {
              return (
                <p key={statusCode} className="text-sm bg-primary-stroke w-max px-4 py-1 rounded-full text-primary-mainText break-all mr-3">
                  {statusCode}
                </p>
              );
            }
          )}
        </div>
      </div>

      <ModelDetailsItem
        label="Error Message"
        value={accordionData?.errorMessage}
      />
    </>
  );
};
