import { ReactNode } from "react";
import { cx } from "../../utils";

export enum FontWeight {
  normal = "font-normal",
  medium = "font-medium",
  semiBold = "font-semibold",
  bold = "font-bold",
}

interface ISectionTitle {
  children: ReactNode;
  className?: string;
  noMargin?: boolean;
  fontWeight?: FontWeight;
}

export const SectionTitle: React.FC<ISectionTitle> = ({
  children,
  className,
  noMargin,
  fontWeight = FontWeight.medium,
}) => {
  return (
    <h5
      className={cx([
        "text-primary-mainText leading-5",
        className,
        !noMargin && "mb-4 sm:mb-6",
        fontWeight,
      ])}
    >
      {children}
    </h5>
  );
};
