import { FC } from 'react';
import { ModelDetailsItem } from './ModelDetailsItem';

interface IOptionPropertiesDetails {
  properties: any;
}

export const OptionPropertiesDetails: FC<IOptionPropertiesDetails> = ({ properties }) => {
  return (
    <>
      {Object.keys(properties).map((key, index) => {
        return (
          <ModelDetailsItem key={index} label={key} value={properties[key]} />
        )
      })}
    </>
  )
};
