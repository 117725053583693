import { FC } from "react";
import { Eye } from "../../../components/Icons";
import { cx } from "../../../utils";

interface IPreviewButton {
  onClick?: () => void;
  disabled?: boolean;
}

export const PreviewButton: FC<IPreviewButton> = ({ onClick, disabled }) => {
  return (
    <button
      type="button"
      onClick={onClick}
      disabled={disabled}
      className={cx([
        disabled
          ? "opacity-70 cursor-default"
          : "hover:bg-gray-100 focus:bg-gray-300",
        "p-2 flex items-center transition font-medium text-sm text-primary-mainText rounded",
      ])}
    >
      <Eye className="mr-1" noInitialWidth width={22} />
      Save & Preview
    </button>
  );
};
