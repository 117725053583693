import { FC } from 'react';

interface INested {
  width?: string;
  height?: string;
}

export const Nested: FC<INested> = ({ width, height }) => {
  return (
    <svg
      width={width || 19}
      height={height || 22}
      style={{
        width: width || 24,
        height: height || 24,
      }}
      viewBox="0 0 19 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line x1="19" y1="0.5" x2="1" y2="0.499998" stroke="#E0E5EB" />
      <line x1="0.5" y1="2.18557e-08" x2="0.499999" y2="22" stroke="#E0E5EB" />
    </svg>
  );
};
