import { FC } from 'react';
import { Modal, ModalSize } from '../../../components';

interface ISaveChangesModal {
  isVisible: boolean;
  toggleModal: any;
  onSubmit: any;
  loading: boolean;
  templateName: string;
}

export const SaveChangesModal: FC<ISaveChangesModal> = ({
  isVisible,
  toggleModal,
  onSubmit,
  loading,
  templateName,
}) => {
  return (
    <Modal
      visible={isVisible}
      modalSize={ModalSize.md}
      hide={toggleModal}
      title="Save & Publish"
      onConfirmClick={onSubmit}
      confirmBtnText="Save & Publish"
      withFooter
      blockOutsideClick
      loading={loading}
    >
      <p className="text-sm text-blue-mainText font-medium mb-4">
        You are trying to save & publish the changes made to {templateName}.
      </p>
      <p className="text-sm text-blue-mainText font-normal mt-1">
        Please note that these changes will take effect immediately and be
        applied to the User Profile.
      </p>
    </Modal>
  );
};
