import { createContext, useState, useContext, useEffect } from "react";
import { useFetch } from "../hooks";

const ApplicationContext = createContext<any>(null);

const ApplicationContextProvider: React.FC<{ children: any }> = ({
  children,
}) => {
  const [appData, setAppData] = useState<object>();
  const [appId, setAppId] = useState<string>();
  const { apiCall: getApplication, loading, errors } = useFetch("get");

  useEffect(() => {
    if (!!appId) {
      getApplication(`/Clients/${appId}`, {}, (response) => {
        setAppData(response);
      });
    }
  }, [appId]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ApplicationContext.Provider
      value={{
        appData,
        setAppData,
        setAppId,
        loading,
        errors,
      }}
    >
      {children}
    </ApplicationContext.Provider>
  );
};

const useApplicationContext = () => {
  return useContext(ApplicationContext);
};

export { ApplicationContextProvider, useApplicationContext };
