import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

export const SortableApp = (props: any) => {
  const sortable = useSortable({ id: props.id });
  const { attributes, listeners, setNodeRef, transform, transition } = sortable;

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      // {...props}
      {...attributes}
      {...listeners}
    >
      <div className="h-16 w-16 rounded border border-primary-stroke">
        <img
          src={props.picture}
          alt={props.name}
          title={props.name}
          className="w-full h-full object-cover"
        />
      </div>
    </div>
  );
};
