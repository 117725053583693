const COUNT_FORMATS = [
    {
      // 0 - 999
      letter: "",
      limit: 1e3,
    },
    {
      // 1,000 - 999,999
      letter: "K",
      limit: 1e6,
    },
    {
      // 1,000,000 - 999,999,999
      letter: "M",
      limit: 1e9,
    },
    {
      // 1,000,000,000 - 999,999,999,999
      letter: "B",
      limit: 1e12,
    },
    {
      // 1,000,000,000,000 - 999,999,999,999,999
      letter: "T",
      limit: 1e15,
    },
  ];

  interface IFormat {
    letter: string;
    limit: number;
  }

  export const formatNumberCount = (value: number) => {
    const format: IFormat = COUNT_FORMATS.find(
      (format) => value < format.limit
    ) || {
      letter: "",
      limit: 0,
    };

    value = (1000 * value) / format?.limit;
    value = Math.round(value * 10) / 10; 

    return value + format?.letter;
  };