import { FC } from "react";

interface IOpenInNewTab {
  width?: string;
  height?: string;
}

export const OpenInNewTab: FC<IOpenInNewTab> = ({ width, height }) => {
  return (
    <svg
      width={width || "32"}
      height={height || "32"}
      viewBox={`0 0 ${width || 32} ${width || 32}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="fill-current"
      style={{
        width: width || 32,
        height: height || 32,
      }}
    >
      <path d="M10.75 3C10.75 2.58579 10.4142 2.25 10 2.25H6C3.92893 2.25 2.25 3.92893 2.25 6V18C2.25 20.0711 3.92893 21.75 6 21.75H18C20.0711 21.75 21.75 20.0711 21.75 18V14C21.75 13.5858 21.4142 13.25 21 13.25C20.5858 13.25 20.25 13.5858 20.25 14V18C20.25 19.2426 19.2426 20.25 18 20.25H6C4.75736 20.25 3.75 19.2426 3.75 18V6C3.75 4.75736 4.75736 3.75 6 3.75H10C10.4142 3.75 10.75 3.41421 10.75 3Z" />
      <path d="M14.25 3C14.25 2.58579 14.5858 2.25 15 2.25H21.75V9C21.75 9.41421 21.4142 9.75 21 9.75C20.5858 9.75 20.25 9.41421 20.25 9V4.81066L10.5303 14.5303C10.2374 14.8232 9.76256 14.8232 9.46967 14.5303C9.17678 14.2374 9.17678 13.7626 9.46967 13.4697L19.1893 3.75H15C14.5858 3.75 14.25 3.41421 14.25 3Z" />
    </svg>
  );
};
