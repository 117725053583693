import { FC, useEffect } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { Accordion, Card, CardSpace, CardStyle } from '../../../../components';
import { MODELS_PATH, USER_PROFILE_API, USER_PROFILE_API_HEADERS } from '../../../../constants';
import { useFetch } from '../../../../hooks';
import { history } from '../../../../utils';
import { IOption } from '../../interface';
import { ModelDetailsItem } from './ModelDetailsItem';
import { OptionApiAccordionDetails } from './OptionApiAccordionDetails';
import { OptionPropertiesDetails } from './OptionPropertiesDetails';

export const ModelOptionsDetails: FC = () => {
  let { modelId } = useParams();

  const {
    apiCall: getModelOptions,
    loading: optionsLoading,
    response,
  } = useFetch("get");

  const options = response?.data

  useEffect(() => {
    if (!!modelId) {
      getModelOptions(
        `${USER_PROFILE_API}/integration-models/options?integrationStructureId=${modelId}`,
        USER_PROFILE_API_HEADERS
        );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modelId])

  return (
    <Card
      cardStyle={CardStyle.bordered}
      cardSpace={CardSpace.xl}
      className="mt-8 data-import-tab"
    >
      {!optionsLoading && !!options?.length && (
        <>
          <div className='flex justify-end mb-5'>
            <button
              type="button"
              onClick={() => {
                history.push(`${MODELS_PATH}/${modelId}/model-options`);
              }}
              className="text-primary-mainText bg-gray-50 border border-primary-stroke hover:bg-gray-200 text-sm font-medium px-4 rounded h-8 focus:shadow-outlineGray"
            >
              Edit
            </button>
          </div>
          {options?.map((option: IOption, index: number) => {
            return (
              <Accordion
                title={`Option - ${index + 1}`}
                opened={true}
                key={index}
                className="flex-1 mb-4"
                accordionClassName="mb-6 border-l-2 border-r-2 border-b-2"
                content={
                  <>
                    <OptionPropertiesDetails properties={option?.propertyValues} />
                    <ModelDetailsItem
                      label="Model Action Type"
                      value="Toggle"
                    />
                    {option?.enableAction !== null ? 
                      <>
                        <Accordion
                          title="API Call Enabled"
                          opened={true}
                          className="mb-4"
                          content={
                            <OptionApiAccordionDetails
                              accordionData={option?.enableAction}
                            />
                          }
                        />
                        <Accordion
                          title="API Call Disabled"
                          opened={true}
                          className="flex-1"
                          content={
                            <OptionApiAccordionDetails
                              accordionData={option?.disableAction}
                            />
                          }
                        />
                      </>
                      : <>
                        <p className="text-primary-secText mb-2 text-sm">API Calls</p>
                        <p className='italic text-sm'>
                          No Api Calls configured
                        </p>
                      </>
                    }
                  </>
                }
              />
            );
          })}
        </>
      )}
      {!optionsLoading && !options?.length && (
        <div className="mt-28 flex justify-center flex-col items-center">
          <h3 className="text-xl text-primary-mainText mb-4">
            No model options created yet.
          </h3>
          <NavLink
            to={`${MODELS_PATH}/${modelId}/model-options`}
            className="text-md text-primary-300"
          >
            <span className="text-xl mr-1">+</span>Create Model Options
          </NavLink>
        </div>
      )}
    </Card>
  );
};
