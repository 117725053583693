import { FC } from "react";
import { cx } from "../../utils";

interface ILogoLoader {
  className?: string;
  noPadding?: boolean;
}

export const Loader: FC<ILogoLoader> = ({ className, noPadding }) => {
  return (
    <div
      className={cx([
        "h-full w-full text-center inline-block",
        className,
        noPadding ? "" : "py-40 ",
      ])}
    >
      <div className="relative inline-block">
        <svg
          width="38"
          height="38"
          viewBox="0 0 38 38"
          xmlns="http://www.w3.org/2000/svg"
          stroke="#11244a"
        >
          <g fill="none" fillRule="evenodd">
            <g transform="translate(1 1)" strokeWidth="2">
              <circle strokeOpacity=".4" cx="18" cy="18" r="18" />
              <path d="M36 18c0-9.94-8.06-18-18-18">
                <animateTransform
                  attributeName="transform"
                  type="rotate"
                  from="0 18 18"
                  to="360 18 18"
                  dur="1s"
                  repeatCount="indefinite"
                />
              </path>
            </g>
          </g>
        </svg>

        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="6.15"
          height="14.64"
          viewBox="0 0 6.15 14.64"
          style={{
            width: 16,
            height: 16,
          }}
          className="transform -translate-x-1/2 -translate-y-1/2 absolute left-1/2 top-1/2"
        >
          <path
            id="Path_7"
            data-name="Path 7"
            d="M84.614,3.092l-2.9,6.866a.519.519,0,0,0,0,.127.565.565,0,0,0,.025.127l3.19,6.753a.579.579,0,0,0,.169.235.631.631,0,0,0,.267.132.654.654,0,0,0,.3,0,.629.629,0,0,0,.264-.138l1.723-2.427c.175-.235.26-.414.17-.635l-1.968-4a.59.59,0,0,1-.025-.122.352.352,0,0,1,0-.127l1.8-4.063c.085-.226,0-.4-.2-.63L85.605,2.838a.628.628,0,0,0-.264-.119.649.649,0,0,0-.292.01.622.622,0,0,0-.254.136.57.57,0,0,0-.157.231"
            transform="translate(-81.71 -2.707)"
            fill="#05c45b"
          />
        </svg>

        {/* <img
          src="/images/porta-favicon-gray.svg"
          alt="Porta logo"
          className="transform -translate-x-1/2 -translate-y-1/2 absolute left-1/2 top-1/2"
          width={16}
        /> */}
      </div>
    </div>
  );
};
