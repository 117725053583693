import { FC } from "react";

interface IEdit {
  width?: string;
  height?: string;
}

export const Edit: FC<IEdit> = ({ width, height }) => {
  return (
    <svg
      width={width || "24"}
      height={height || "24"}
      viewBox="0 0 24 24"
      style={{
        width: width || 24,
        height: height || 24,
      }}
      className="fill-current"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.8839 4.94455C17.3957 4.45639 16.6043 4.45639 16.1161 4.94455L4.11612 16.9445C3.8817 17.179 3.75 17.4969 3.75 17.8284V20.25H6.17157C6.50309 20.25 6.82104 20.1183 7.05546 19.8839L19.0555 7.88389C19.5436 7.39573 19.5436 6.60428 19.0555 6.11612L17.8839 4.94455ZM15.0555 3.88389C16.1294 2.80994 17.8706 2.80995 18.9445 3.88389L20.1161 5.05546C21.1901 6.1294 21.1901 7.87061 20.1161 8.94455L8.11612 20.9445C7.60039 21.4603 6.90092 21.75 6.17157 21.75H3.5C2.80964 21.75 2.25 21.1904 2.25 20.5V17.8284C2.25 17.0991 2.53973 16.3996 3.05546 15.8839L15.0555 3.88389Z"
      />
    </svg>
  );
};
