export const TemplateHeadline = () => {
  return (
    <div className="flex border-b  border-primary-stroke py-2 mb-4 mt-8">
      <div className="text-xs text-primary-secText w-96 font-medium">
        Template Name
      </div>
      <div className="text-xs text-primary-secText ml-[61px] font-medium w-32">
        Last Update
      </div>
      <div className="text-xs text-primary-secText font-medium">Updated By</div>
    </div>
  );
};
