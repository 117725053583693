import { CopyText } from "../ui/CopyText";
import { Avatar, AvatarRounded, AvatarSize } from "..";
import { cx } from "../../utils";

export interface ITopDetails {
  imgUrl: string;
  title: string;
  message?: string;
  className?: string;
  textToCopy?: string;
  index?: number;
  copyTextLabel?: string;
  rounded?: boolean;
  isBlocked?: boolean;
  protocolType?: string;
}

export const TopDetails: React.FC<ITopDetails> = (props) => {
  return (
    <div
      className={cx([
        "flex jusify-between relative sm:pb-8 min-w-0",
        props.className,
      ])}
    >
      <Avatar
        imgUrl={props.imgUrl}
        text={props.title}
        colourful
        index={props.index}
        size={AvatarSize.lg}
        rounded={
          props.rounded ? AvatarRounded["rounded-full"] : AvatarRounded.rounded
        }
      />

      <div className="text-primary-mainText ml-4 min-w-0">
        <h3 className="text-md font-medium break-all flex items-center">
          {props.title}

          {props.isBlocked && (
            <span className="bg-warning text-white text-sm px-3 py-1 rounded-full ml-3">
              Blocked
            </span>
          )}
        </h3>

        <div className="mt-2 flex flex-col text-sm text-blue-secText min-w-0">
          <div className="flex">
            {props.message && (
              <div className="sm:mr-4 mb-2">
                Client Type:{" "}
                <span className="text-primary-mainText font-medium">
                  {props.message}
                </span>
              </div>
            )}

            {props.protocolType && (
              <div className="sm:mr-4 mb-2">
                Protocol Type:{" "}
                <span className="text-primary-mainText font-medium">
                  OpenID Connect
                </span>{" "}
              </div>
            )}
          </div>

          {!!Boolean(props.textToCopy) && (
            <CopyText
              text={props.textToCopy || ""}
              className="max-w-full sm:relative "
              iconSize={18}
              copyTextLabel={props.copyTextLabel}
              truncate
            />
          )}
        </div>
      </div>
    </div>
  );
};
