import { colors, cx } from "../../utils";

export enum AvatarSize {
  "sm" = "w-8 h-8 text-sm",
  "md" = "w-11 h-11 text-lg",
  "lg" = "w-20 h-20 text-3xl",
}

export enum AvatarRounded {
  "rounded" = "rounded",
  "classic" = "",
  "rounded-full" = "rounded-full",
}

interface IAvatar {
  className?: string;
  text: string;
  imgUrl: string;
  alt?: string;
  index?: number;
  colourful?: boolean;
  size?: AvatarSize;
  rounded?: AvatarRounded;
}

export const Avatar: React.FC<IAvatar> = ({
  className,
  text,
  imgUrl,
  alt,
  index,
  colourful,
  size,
  rounded,
}) => {
  return (
    <>
      {!!imgUrl ? (
        <div
          className={cx([
            "flex-shrink-0 border border-primary-stroke flex items-center",
            className,
            size || AvatarSize.md,
            rounded || AvatarRounded.rounded,
          ])}
        >
          <img
            src={imgUrl}
            alt={alt}
            className={cx([
              "w-full object-cover h-full",
              rounded || AvatarRounded.rounded,
            ])}
          />
        </div>
      ) : (
        <div
          className={cx([
            "flex items-center justify-center font-medium flex-shrink-0 ",
            className,
            size || AvatarSize.md,
            rounded || AvatarRounded.rounded,
            !colourful && "text-primary-secText bg-primary-stroke",
          ])}
          style={{
            backgroundColor: colourful
              ? colors[(index || 0) % colors.length].bgColor
              : "",
            color: colourful
              ? colors[(index || 0) % colors.length].textColor
              : "",
          }}
        >
          {text?.charAt(0).toUpperCase()}
        </div>
      )}
    </>
  );
};
