export const showCurrentTableItems = (key: string, res: any) => {
    switch (key) {
      case "apiResources":
        return res?.apiResources;

      case "identityResources":
        return res?.identityResources;

      case "scopes":
        return res?.scopes;

      default:
        return [];
    }
  };

  export const showCurrentTags = (key: string, res: any) => {
    switch (key) {
      case "apiResources":
        return res?.scopes;

      case "identityResources":
        return res?.userClaims;

      case "scopes":
        return res?.userClaims;

      default:
        return [];
    }
  };