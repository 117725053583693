import { v4 as uuidv4 } from "uuid";

export const newAppState = {
  absoluteRefreshTokenLifetime: 2592000,
  accessTokenLifetime: 3600,
  consentLifetime: 0,
  accessTokenType: 0,
  allowAccessTokensViaBrowser: true,
  allowOfflineAccess: false,
  allowPlainTextPkce: false,
  allowRememberConsent: true,
  alwaysIncludeUserClaimsInIdToken: false,
  alwaysSendClientClaims: false,
  authorizationCodeLifetime: 300,
  frontChannelLogoutUri: "",
  frontChannelLogoutSessionRequired: true,
  backChannelLogoutUri: "",
  backChannelLogoutSessionRequired: true,
  clientId: "",
  clientName: "",
  clientUri: "",
  clientType: "",
  description: "",
  enabled: true,
  enableLocalLogin: true,
  id: 0,
  identityTokenLifetime: 300,
  includeJwtId: true,
  logoUri: null,
  clientClaimsPrefix: "",
  pairWiseSubjectSalt: "",
  protocolType: "oidc",
  refreshTokenExpiration: 1,
  refreshTokenUsage: 1,
  slidingRefreshTokenLifetime: 1296000,
  requireClientSecret: false,
  requireConsent: true,
  requirePkce: false,
  updateAccessTokenClaimsOnRefresh: true,
  postLogoutRedirectUris: [],
  identityProviderRestrictions: [],
  redirectUris: [],
  allowedCorsOrigins: [],
  allowedGrantTypes: [],
  allowedScopes: [],
  // claims: [
  //   {
  //     id: 0,
  //     type: "string",
  //     value: "string",
  //   },
  // ],
  properties: [],
  updated: "2021-05-20T08:35:04.257Z",
  lastAccessed: "2021-05-20T08:35:04.257Z",
  userSsoLifetime: null,
  userCodeType: "",
  deviceCodeLifetime: 0,
  requireRequestObject: false,
  allowedIdentityTokenSigningAlgorithms: [],
};

export const secretIState = {
  id: 0,
  type: {
    label: "SharedSecret",
    value: "SharedSecret",
  },
  description: "",
  created: new Date(),
  value: "",
  hashType: {
    label: "Sha256",
    value: "Sha256",
  },
  hashTypeEnum: 0,
  expiration: null,
};

export const propertiesIState = {
  id: 0,
  key: "",
  value: "",
};

// API Resources

export const apiResourceIState = {
  id: 0,
  name: "",
  createdDate: new Date(),
  displayName: "",
  description: "",
  enabled: true,
  showInDiscoveryDocument: true,
  userClaims: [],
  allowedAccessTokenSigningAlgorithms: [],
  scopes: [],
};

export const appDataTableItemIState = {
  ...apiResourceIState,
};

export const apiResourcesIState = {
  apiResources: [apiResourceIState],
  pageSize: 10,
  totalCount: 0,
};

export const appDataTableIState = {
  items: [appDataTableItemIState],
  pageSize: 10,
  totalCount: 0,
};

export const userIState = {
  id: null,
  userName: "",
  picture: "",
  firstName: "",
  lastName: "",
  identityProvider: "",
  lastLogin: null,
  email: "",
  emailConfirmed: false,
  phoneNumber: null,
  phoneNumberConfirmed: false,
  twoFactorEnabled: false,
  lockoutEnd: null,
  isBlocked: false,
  colorId: 0,
};
export const scanIState = {
  completed: "",
  cookiesDiscovered: 0,
  created: "",
  domainId: null,
  isUserInitiated: false,
  message: "",
  pagesDiscovered: 0,
  statusEnum: 1,
};

export const usersIState = {
  users: [],
  pageSize: 10,
  totalCount: 0,
};

// Api scopes
export const apiScopeIState = {
  id: 0,
  name: "",
  displayName: "",
  description: "",
  enabled: true,
  required: false,
  showInDiscoveryDocument: true,
  userClaims: [],
};

export const identityResourceIState = {
  ...apiScopeIState,
};

// Roles

export const roleIState = {
  id: "",
  name: "",
  description: "",
};

export const rolesIState = {
  roles: [roleIState],
  pageSize: 10,
  totalCount: 0,
};

// Logs

export const logIState = {
  id: 0,
  event: "",
  source: "",
  category: 0,
  subjectIdentifier: "",
  subjectName: "",
  subjectType: "",
  subjectAdditionalData: "",
  action: "",
  data: "",
  created: new Date(),
};

export const errorLogIState = {
  id: 0,
  message: "",
  timeStamp: "",
  exception: "",
  logEvent: "",
};

export const logsIState = {
  logs: [logIState],
  pageSize: 10,
  totalCount: 0,
  deleteOlderThan: new Date(),
};

// Consent management
export const domainIState = {
  id: 0,
  name: "",
  encryptedId: "",
  guidId: "",
  websiteUrl: "",
  privacyPolicyUrl: "",
  application: "",
  cookieCount: 0,
  languageId: 1,
  code: "",
  cookies: [],
  language: {
    id: 1,
    name: "",
    nativeName: "",
    code: "",
    flag: "",
    isDefault: true,
    // domains: [],
  },
  languageList: [],
};

export const pagingInfoIState = {
  totalItems: 0,
  itemsPerPage: 0,
  currentPage: 0,
  urlParam: "",
  totalPage: 0,
};

export const domainsIState = {
  domains: [domainIState],
  pagingInfo: pagingInfoIState,
};

export const cookieCategoryIState = {
  // encryptedId: null,
  name: "",
  isDefault: false,
  isDeleted: false,
  isEnabled: false,
  description: "",
  domainId: "",
  encryptedDomainId: "",
};

// UI Builder Consent styles
export const consentStyleIState = {
  id: uuidv4(),
  // encryptedId: "",
  activeTheme: false,
  bannerName: "",
  bannerTitle: "We value your privacy",
  bannerPlacement: "bottom",
  widgetPosition: "bottom-left",
  showLanguagesIcon: true,
  showDNSMD: true,
  showCloseButton: true,
  showBrandLogo: true,
  brandLogoURL: "https://via.placeholder.com/100",
  widgetColor: "#0000ff",
  widgetType: "circle",
  backgroundColor: "#ffffff",
  borderColor: "#000000",
  fontColorMain: "#000000",
  fontColorSecondary: "#0000ff",
  iconsColor: "#0000ff",
  privacyLinkColor: "#0000ff",
  cookieLinkColor: "#0000ff",
  toggleButtonEnabled: "#0000ff",
  toggleButtonDisabled: "#cccccc",
  fontFamily: "Inter",
  bannerLanguage: "English",
  learnMore: "learn more",
};

// UI Builder Consent styles
export const defaultConsentIState = {
  id: uuidv4(),
  encryptedId: uuidv4(),
  activeTheme: true,
  bannerName: "Default Template",
  bannerTitle: "We value your privacy",
  bannerPlacement: "bottom",
  widgetPosition: "bottom-left",
  showLanguagesIcon: true,
  showDNSMD: false,
  showCloseButton: true,
  showBrandLogo: true,
  brandLogoURL: "https://via.placeholder.com/100",
  widgetColor: "#0000ff",
  widgetType: "circle",
  backgroundColor: "#ffffff",
  borderColor: "#000000",
  fontColorMain: "#000000",
  fontColorSecondary: "#0000ff",
  iconsColor: "#0000ff",
  privacyLinkColor: "#0000ff",
  cookieLinkColor: "#0000ff",
  toggleButtonEnabled: "#0000ff",
  toggleButtonDisabled: "#cccccc",
  fontFamily: "Inter",
  bannerLanguage: "English",
  learnMore: "learn more",
};
export const consentStylesAllIState = {
  consentStyles: [consentStyleIState],
  pagingInfo: pagingInfoIState,
};

export const complianceConsentIState = {
  id: "e7dbuhds8hd3883jcvulaksjf03",
  domainId: "e7dbuhds8hd3883jcvulaksjf03",
  geolocationEnum: 2,
  geolocationEnumAsString: "GDPRCompliant",
  showDNSMD: true,
  userConsent: true,
};
export const complianceConsentAllIState = {
  complianceConsents: [complianceConsentIState],
  pagingInfo: pagingInfoIState,
};

// Branding
export const brandingConfigItemIState = {
  applicationsDto: [],
  companyLogo: null,
  dateCreated: new Date(),
  dateUpdated: null,
  file: null,
  _CardBackgroundColor: "#ffffff",
  _PageBackgroundColor: "#ffffff",
  _PrimaryColor: "#1D79F2",
  id: 0,
  isDefault: false,
  name: "",
};

export const brandingResponseIState = {
  items: [brandingConfigItemIState],
  pageSize: 10,
  totalCount: 0,
};

// Attack Protection

export const bruteForceProtectionIState = {
  loginTresholdForAccountLockout: 0,
  bruteForceProtection: false,
  blockBruteForceLogins: false,
  accountLockout: false,
  sendEmailNotificationsForBruteForce: false,
  ipAddressAllowedDtos: [],
};
