import {
  ButtonHTMLAttributes,
  FC,
  Fragment,
  ReactNode,
  TableHTMLAttributes,
  ThHTMLAttributes,
} from "react";
import { Loader, Pagination, PaginationProps } from "..";
import { cx } from "../../utils";

export interface TableProps {
  loading?: boolean;
  wrapperClassName?: string;
  table?: TableHTMLAttributes<HTMLTableElement>;
  namesThClassName?: string;
  loaderClassName?: string;
  names: ThHTMLAttributes<HTMLTableHeaderCellElement>[];
  values: any[];
  renderTr: (el: any, key?: number) => ReactNode;
  emptyView?: {
    icon?: boolean;
    text?: string;
    buttonProps?: ButtonHTMLAttributes<HTMLButtonElement>;
  };
  pagination?: PaginationProps;
}

export const Table: FC<TableProps> = ({
  loading = false,
  wrapperClassName = "w-full pt-2 flex-grow flex flex-col",
  namesThClassName,
  loaderClassName,
  table,
  names,
  values,
  renderTr,
  emptyView,
  pagination,
}) => {
  return (
    <div
      className={cx([
        "relative table-wrapper h-full flex flex-col flex-grow",
        wrapperClassName,
        loading && "opacity-70 pointer-events-none",
      ])}
    >
      <div className="mb-auto">
        <table className="bg-white w-full" {...table}>
          <thead className="border-b border-primary-stroke">
            <tr>
              {names?.map(({ className, ...el }, key) => (
                <th
                  key={key}
                  align="left"
                  className={cx([
                    !namesThClassName &&
                      "text-xs text-primary-secText font-medium py-2 px-3 select-none",
                    // !namesThClassName &&
                    //   key !== 0 &&
                    //   "border-l border-primary-stroke",
                    (!namesThClassName || className) && "text-primary-secText",
                    namesThClassName,
                    className,
                  ])}
                  {...el}
                />
              ))}
            </tr>
          </thead>
          <tbody className=" border-primary-stroke">
            {values?.map((el, key) => (
              <Fragment key={key}>{renderTr && renderTr(el, key)}</Fragment>
            ))}
          </tbody>
        </table>
        {loading && (
          <Loader className={cx(["absolute top-0", loaderClassName])} />
        )}

        {emptyView && !values?.length && !loading && (
          <div className="flex justify-center items-center flex-col my-4 mb-auto py-6 bg-gray-100 rounded">
            {/* {emptyView.icon && <Settings />} */}
            <p className="text-primary-secText text-sm">
              {emptyView.text && emptyView.text}
            </p>
            {emptyView.buttonProps && (
              <button {...emptyView.buttonProps} className="text-primary-500" />
            )}
          </div>
        )}
      </div>

      {pagination && (
        <Pagination
          {...pagination}
          className={cx([
            "border-t border-primary-stroke pt-4 pl-2 pb-1",
            pagination.className,
          ])}
        />
      )}
    </div>
  );
};
