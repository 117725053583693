import { ReactNode } from "react";
import { cx } from "../../utils";

export enum ParagraphSize {
  xs = "text-xs leading-5",
  sm = "text-sm",
}

interface IParagraph {
  children: ReactNode;
  className?: string;
  noMargin?: boolean;
  parahraphSize?: ParagraphSize;
}

export const Paragraph: React.FC<IParagraph> = ({
  className,
  noMargin,
  children,
  parahraphSize = ParagraphSize.xs,
}) => {
  return (
    <p
      className={cx([
        "text-xs text-primary-secText leading-5",
        className,
        !noMargin && "mt-2",
        parahraphSize,
      ])}
    >
      {children}
    </p>
  );
};
