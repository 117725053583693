import { FC, ReactNode } from "react";
import { Routes, Route } from "react-router-dom";
import { cx } from "../../utils";

interface IRoute {
  path: string;
  element: any;
  index?: boolean;
}

interface ITabs {
  children: ReactNode;
  className?: string;
  wrapperClassname?: string;
  routes: IRoute[];
}

export const Tabs: FC<ITabs> = ({
  children,
  className,
  routes,
  wrapperClassname,
}) => {
  return (
    <div className={cx(["flex-1", wrapperClassname])}>
      <div
        className={cx([
          "tab-list -m-pb flex border-b border-gray-200 justify-start overflow-auto sm:overflow-hidden relative",
          className && className,
        ])}
      >
        {children}

        {/* <div className="tab-moving-line bg-primary"></div> */}
      </div>

      <Routes>
        {routes.map((route) => (
          <Route
            path={route.path}
            element={route.element}
            key={route.path}
            index={route?.index}
          />
        ))}
      </Routes>
    </div>
  );
};
