import {
  InputHTMLAttributes,
  ReactNode,
  isValidElement,
  ReactElement,
  forwardRef,
} from "react";
import { Label, LabelProps } from "../label";
import { Message } from "../messages";
import { cx } from "../../utils/classnames";

export enum InputSize {
  sm = "py-2 px-3",
  md = "p-3",
  lg = "px-3 py-4",
}

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  labelProps?: LabelProps;
  labelClassname?: string;
  // error?: string;
  // TODO: should check this error
  error?: any;
  success?: string;
  containerClassname?: string;
  before?: ReactElement;
  after?: ReactElement;
  inputSize?: InputSize;
}

export const Input = forwardRef(
  (
    {
      containerClassname,
      labelProps,
      labelClassname,
      error,
      success,
      inputSize = InputSize.md,
      before,
      after,
      className,
      ...props
    }: InputProps,
    ref: any
  ) => (
    <div className={containerClassname}>
      {labelProps && (
        <Label
          className={cx(["mb-1.5", labelClassname])}
          {...labelProps}
          htmlFor={labelProps.htmlFor || props.id || props.name}
          error={!!error}
          success={!!success}
        />
      )}
      <div className="w-full relative">
        {renderIcon(before, cx(["left-0", inputSize]))}
        <input
          {...props}
          id={props.id || props.name}
          ref={ref}
          className={cx([
            "text-sm leading-4.5 min-w-full rounded focus:outline-none focus:border focus:border-primary",
            className,
            !success && !error && "border border-gray-300",
            success && "border border-confirm",
            !!error &&
              "border border-warning focus:shadow-warning focus:border-warning",
            inputSize,
            after && "pr-8",
            before && "pl-8",
          ])}
        />
        {renderIcon(after, cx([`right-0`, inputSize]))}
      </div>
      {error && <Message message={error} containerClassName="mt-2" />}
    </div>
  )
);

export const renderIcon = (icon: ReactNode, className: string) =>
  isValidElement(icon) && (
    <div className={cx(["absolute inset-y-0 flex items-center", className])}>
      {icon}
    </div>
  );
