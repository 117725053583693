import { FC } from 'react';
import { Label } from '../../../../components';

interface IModelDetailsItem {
  label: string;
  value: string;
}

export const ModelDetailsItem: FC<IModelDetailsItem> = ({ label, value }) => {
  return (
    <div className="mb-6">
      <Label text={label} />
      {value ? (
        <p className="text-sm font-medium text-primary-mainText break-all">
          {value}
        </p>
      ) : (
        <p className="italic text-sm">no value</p>
      )}
    </div>
  );
};
