import { FC } from "react";

import { Paragraph, ParagraphSize } from "..";
import { cx } from "../../utils";

interface IPageHeadeline {
  className?: string;
  title: string;
  description: string;
}

export const PageHeadeline: FC<IPageHeadeline> = ({
  className,
  title,
  description,
}) => {
  return (
    <div className={cx([className])}>
      <h3 className="text-primary-mainText font-medium">{title}</h3>
      <Paragraph parahraphSize={ParagraphSize.sm} noMargin className="mt-1">
        {description}
      </Paragraph>
    </div>
  );
};
