import { ReactNode } from "react";
import { cx } from "../../utils";

interface ISectionTitle {
  children: ReactNode;
  className?: string;
}

export const DividedSection: React.FC<ISectionTitle> = ({
  children,
  className,
}) => {
  return (
    <div
      className={cx([
        "text-sm text-primary-mainText border-b border-primary-stroke py-2 mb-4",
        className,
      ])}
    >
      {children}
    </div>
  );
};
