import { useNavigate } from "react-router-dom";

import { BtnSize, BtnStyle, Button } from "../components";
import { cx, primaryBtnClasses } from "../utils";

export const Page404 = () => {
  const navigate = useNavigate();

  return (
    <div className="error-page-wrapper w-full flex items-center justify-center">
      <div className="flex items-center justify-center flex-col max-w-screen-sm text-center">
        <img src="/images/error-indicator.png" alt="Error" width={380} />
        <h1 className="mb-4 text-primary-mainText font-bold mt-10">
          404 - Page not found
        </h1>
        <h4 className="text-primary-mainText mb-12">
          The requested URL was not found on this server.
        </h4>

        <div className="flex">
          <Button
            type="button"
            onClick={() => navigate("/")}
            btnStyle={BtnStyle.primary}
            btnSize={BtnSize.normal}
            className={cx([
              "inline-flex items-center align-middle",
              primaryBtnClasses,
            ])}
          >
            Back to Dashboard
          </Button>
        </div>
      </div>
    </div>
  );
};
