interface IManagePremiumFeatures {
  sms: boolean;
  email: boolean;
  authApp: boolean;
  authFlow: boolean;
  passwordLess: boolean;
  consent: boolean;
  userDeletion: boolean;
  authorizedApplication: boolean;
  userDevices: boolean;
  userProfileModels: boolean;
}

export const premiumFeatureEnabled: IManagePremiumFeatures = {
  sms: process.env.REACT_APP_ENABLE_SMS_MFA === "true" ? true : false,
  email: process.env.REACT_APP_ENABLE_EMAIL_MFA === "true" ? true : false,
  authApp: process.env.REACT_APP_ENABLE_AUTH_APP_MFA === "true" ? true : false,
  authFlow: process.env.REACT_APP_ENABLE_AUTH_FLOW === "true" ? true : false,
  passwordLess:
    process.env.REACT_APP_ENABLE_PASSWORD_LESS === "true" ? true : false,
  consent: process.env.REACT_APP_ENABLE_CONSENT === "true" ? true : false,
  userDeletion:
    process.env.REACT_APP_ENABLE_MANAGE_USER_DELETION === "true" ? true : false,
  authorizedApplication:
    process.env.REACT_APP_ENABLE_AUTHORIZED_APPS === "true" ? true : false,
  userDevices:
    process.env.REACT_APP_ENABLE_USER_DEVICES === "true" ? true : false,
  userProfileModels:
    process.env.REACT_APP_ENABLE_USER_PROFILE_MODELS === "true" ? true : false,
};
