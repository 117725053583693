import { cx } from "../../utils";

interface IInitials {
  className?: string;
  text: string;
  middleRounded?: boolean;
}

export const Initials: React.FC<IInitials> = ({
  className,
  text,
  middleRounded,
}) => {
  return (
    <div
      className={cx([
        "bg-primary-stroke flex items-center justify-center text-primary-secText flex-shrink-0",
        className,
        middleRounded
          ? "rounded w-10 h-10 text-xl"
          : "rounded-full w-8 h-8 text-sm",
      ])}
    >
      {text?.charAt(0).toUpperCase()}
    </div>
  );
};
