import { ReactElement, ReactNode } from "react";
import { cx } from "../../utils";
import { Paragraph } from "../ui/Paragraph";

interface IFormControl {
  lastChild?: boolean;
  children: ReactNode;
  className?: string;
  description?: ReactElement | string;
}

export const FormControl: React.FC<IFormControl> = ({
  lastChild,
  children,
  className,
  description,
}) => {
  return (
    <div className={cx([!lastChild && "mb-6", className, "relative"])}>
      {children}

      {description && <Paragraph>{description}</Paragraph>}
    </div>
  );
};
