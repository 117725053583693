import { FC } from "react";

interface IRefresh {
  size?: number;
}

export const Refresh: FC<IRefresh> = ({ size = 24 }) => {
  return (
    <span className="w-6 h-6">
      <svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="fill-current"
      >
        <path
          d="M4.75 12C4.75 8.01619 8.08027 4.75 12.2343 4.75C15.3719 4.75 18.0465 6.6178 19.1615 9.25L17 9.25C16.5858 9.25 16.25 9.58579 16.25 10C16.25 10.4142 16.5858 10.75 17 10.75H20.5C21.1904 10.75 21.75 10.1904 21.75 9.5V6C21.75 5.58579 21.4142 5.25 21 5.25C20.5858 5.25 20.25 5.58579 20.25 6V8.04407C18.764 5.19282 15.7265 3.25 12.2343 3.25C7.29295 3.25 3.25 7.14725 3.25 12C3.25 16.8527 7.29295 20.75 12.2343 20.75C16.1337 20.75 19.4662 18.3277 20.7048 14.9231C20.8464 14.5338 20.6457 14.1035 20.2564 13.9619C19.8672 13.8202 19.4368 14.021 19.2952 14.4103C18.273 17.22 15.5053 19.25 12.2343 19.25C8.08027 19.25 4.75 15.9838 4.75 12Z"
          fill="#1D79F2"
        />
      </svg>
    </span>
  );
};
