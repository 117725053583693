import { FC } from "react";

interface IBell {
  width?: string;
  height?: string;
}

export const Bell: FC<IBell> = ({ width, height }) => {
  return (
    <svg
      width={width || "32"}
      height={height || "32"}
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="fill-current"
      style={{
        width: width || 32,
        height: height || 32,
      }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.3334 24.1667H8.55224C6.47346 24.1667 5.43241 21.6533 6.90233 20.1834L8.23566 18.8501C8.29817 18.7876 8.33329 18.7028 8.33329 18.6144V11.1044L8.34101 11.0426C8.47503 9.97052 9.01965 8.43363 10.2195 7.15036C11.4478 5.83672 13.3235 4.83333 16 4.83333C18.6764 4.83333 20.5521 5.83672 21.7804 7.15036C22.9803 8.43363 23.5249 9.97052 23.6589 11.0426L23.6666 11.1044V18.6144C23.6666 18.7028 23.7017 18.7876 23.7643 18.8501L25.0976 20.1834C26.5675 21.6533 25.5265 24.1667 23.4477 24.1667H19.6667V25.8333C19.6667 27.122 18.622 28.1667 17.3334 28.1667H14.6667C13.378 28.1667 12.3334 27.122 12.3334 25.8333L12.3334 24.1667ZM10.3333 18.6144V11.2332C10.4349 10.5248 10.826 9.43015 11.6804 8.5163C12.5299 7.60772 13.8764 6.83333 16 6.83333C18.1235 6.83333 19.47 7.60772 20.3195 8.5163C21.174 9.43015 21.565 10.5248 21.6666 11.2332V18.6144C21.6666 19.2332 21.9125 19.8267 22.35 20.2643L23.6834 21.5976C23.8934 21.8076 23.7446 22.1667 23.4477 22.1667H8.55224C8.25528 22.1667 8.10655 21.8076 8.31654 21.5976L9.64988 20.2643C10.0875 19.8267 10.3333 19.2332 10.3333 18.6144ZM17.6667 24.1667V25.8333C17.6667 26.0174 17.5175 26.1667 17.3334 26.1667H14.6667C14.4826 26.1667 14.3334 26.0174 14.3334 25.8333V24.1667H17.6667Z"
      />
    </svg>
  );
};
